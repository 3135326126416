<template>
  <div>
    <!-- Back Button -->
    <v-btn icon @click="$router.push('/settings')" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <!-- Title And Description -->
    <h1 class="my-5 font-weight-medium">{{ $t('settings.knowledgeAndExperience.title') }}</h1>
    <p>{{ $t('settings.knowledgeAndExperience.desc') }}</p>

    <!-- K & E -->
    <KnowledgeExperience
      @next="checkOrSave"
      @previous="reset"
      :loading="loading"
      :settings="true"
      :nextDisabled="JSON.stringify($store.state.user.data.investmentSurvey) === JSON.stringify(data.investmentSurvey)"
      :prevDisabled="JSON.stringify($store.state.user.data.investmentSurvey) === JSON.stringify(data.investmentSurvey)"
      :resetData="resetData"
      @clear="clear"
    />

    <!-- Investor Status -->
    <InvestorStatus
      class="my-5"
      :data="data"
      @downgrade="downgradeManually"
    />

    <!-- Leaving Page Warning Modal -->
    <Modal
      :dialog="warningModal"
      @close="closeWarningModal"
      noPadding
    >
      <template v-slot:content>
        <p class="defaultText--text mt-5">
          {{ $t('settings.knowledgeAndExperience.warningModal.title') }}
        </p>
        <p class="defaultText--text">
          {{ $t('settings.knowledgeAndExperience.warningModal.desc') }}
        </p>
        <CardNav
          :nextText="$t('settings.knowledgeAndExperience.warningModal.yes')"
          :prevText="$t('settings.knowledgeAndExperience.warningModal.no')"
          @next="back"
          @previous="closeWarningModal"
          class="mt-5"
        />
      </template>
    </Modal>

    <!-- Changing ProK Warning Modal -->
    <Modal
      :dialog="prokWarningModal"
      @close="closeProkWarningModal"
      noPadding
    >
      <template v-slot:content>
        <p class="defaultText--text mt-5 font-weight-medium">
          {{ $t('settings.knowledgeAndExperience.prokWarningModal.title') }}
        </p>
        <p class="defaultText--text">
          {{ $t('settings.knowledgeAndExperience.prokWarningModal.desc') }}
        </p>
        <div class="half-opacity" v-html="$t('settings.knowledgeAndExperience.prokWarningModal.list')" />
        <CardNav
          :nextText="$t('settings.knowledgeAndExperience.prokWarningModal.yes')"
          :prevText="$t('settings.knowledgeAndExperience.prokWarningModal.no')"
          @next="save"
          @previous="closeProkWarningModal"
          :nextLoading="loading"
          :prevDisabled="loading"
          class="mt-5"
        />
      </template>
    </Modal>

    <!-- Changing ProK Status Modal -->
    <Modal
      :dialog="changeProkModal"
      @close="closeChangeProkModal"
      noPadding
    >
      <template v-slot:content>
        <p class="defaultText--text mt-5 font-weight-medium">
          {{ $t('settings.knowledgeAndExperience.changeProkModal.title') }}
        </p>
        <p class="defaultText--text">
          {{ $t('settings.knowledgeAndExperience.changeProkModal.desc') }}
        </p>
        <div class="half-opacity" v-html="$t('settings.knowledgeAndExperience.changeProkModal.list')" />
        <p class="defaultText--text mt-2">
          {{ $t('settings.knowledgeAndExperience.changeProkModal.footer') }}
        </p>
        <v-btn
          class="mt-5"
          block
          color="primary"
          @click="closeChangeProkModal"
        >
          {{ $t('settings.knowledgeAndExperience.changeProkModal.yes') }}
        </v-btn>
      </template>
    </Modal>

    <!-- Downgrade ProK Status Modal -->
    <Modal
      :dialog="downgradeProkModal"
      @close="closeDowngradeProkModal"
      noPadding
    >
      <template v-slot:content>
        <p class="defaultText--text mt-5 font-weight-medium">
          {{ $t('settings.knowledgeAndExperience.downgradeProkModal.title') }}
        </p>
        <p class="defaultText--text">
          {{ $t('settings.knowledgeAndExperience.downgradeProkModal.desc') }}
        </p>
        <div class="half-opacity">
          <ul class="mt-2">
            <li v-for="(security, index) in securityTypes" :key="index">
              {{ $t(`settings.knowledgeAndExperience.investorStatus.partialUser.${security}`) }}
            </li>
          </ul>
        </div>
        <CardNav
          :nextText="$t('settings.knowledgeAndExperience.downgradeProkModal.yes')"
          :prevText="$t('settings.knowledgeAndExperience.downgradeProkModal.no')"
          @next="save"
          @previous="closeDowngradeProkModal"
          :nextLoading="loading"
          :prevDisabled="loading"
          class="mt-5"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import Modal from '@/components/Shared/Modal.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'KnowledgeExperienceSettings',
  components: {
    KnowledgeExperience: lazyLoad('components/Onboarding/KnowledgeExperience'),
    InvestorStatus: lazyLoad('components/Settings/InvestorStatus'),
    Modal,
    CardNav,
  },
  data() {
    return {
      loading: false,
      data: clone(this.$store.state.user.data),
      warningModal: false,
      prokWarningModal: false,
      changeProkModal: false,
      nextRoute: null,
      allSecurities: ['InvestmentFund', 'GovernmentBonds', 'Bond', 'Share', 'CryptoCurrency', 'UnsecuredJuniorDebt'],
      resetData: null,
      downgrade: false,
      manualDowngrade: false,
      downgradeProkModal: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'investorClassification',
      'investmentSurvey',
    ]),
    securityTypes() {
      if (this.investorClassification.investorClassification === 'Professional') {
        return this.allSecurities;
      }
      return this.investorClassification.professionalSecurities;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'SignIn') {
      if (JSON.stringify(this.$store.state.user.data.investmentSurvey)
        !== JSON.stringify(this.data.investmentSurvey) && !this.warningModal) {
        next(false);
        this.nextRoute = to.name;
        this.warningModal = true;
      } else {
        this.updateUser(this.data.investmentSurvey);
        this.resetData = clone(this.data);
        if (to.name === 'Logout') {
          this.warningModal = true;
          this.$store.dispatch('auth/signout');
          this.$store.dispatch('application/globalStateReset');
          next('/signin');
        } else {
          next();
        }
      }
    } else {
      next();
    }
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'updateInvestmentSurvey',
      'resetInvestorClassificationToPrivate',
    ]),
    downgradeManually() {
      this.downgradeProkModal = true;
      this.manualDowngrade = true;
    },
    async checkOrSave() {
      const {
        investorClassification,
        professionalSecurities,
      } = this.investorClassification;
      const { investmentExperience } = this.investmentSurvey;
      const securityTypes = investorClassification === 'Professional' ? this.allSecurities : professionalSecurities;

      if (investorClassification === 'Private' || this.downgrade) {
        this.save();
      } else {
        securityTypes.forEach((type) => {
          Object.values(investmentExperience).flat().forEach((security) => {
            if (security.type === type && (!security.knowledge || security.experience !== 'GreaterThan5Years')) {
              this.prokWarningModal = true;
            }
          });
        });
        if (!this.prokWarningModal) {
          this.save();
        }
      }
    },
    async save() {
      this.loading = true;
      if (this.manualDowngrade) {
        try {
          await this.resetInvestorClassificationToPrivate();
        } catch (error) {
          this.$notify.error(error.message);
        }
        this.manualDowngrade = false;
        this.downgradeProkModal = false;
        this.loading = false;
        this.$notify.success(this.$t('settings.knowledgeAndExperience.success'));
        return;
      }
      const {
        investmentExperience,
        serviceExperience,
        avgTransactionValue,
        avgYearlyTransactions,
      } = this.$store.state.user.data.investmentSurvey;
      const input = {
        investor: {
          investmentSurvey: {
            products: investmentExperience,
            services: {
              serviceExperience,
              avgYearlyTransactions,
              avgTransactionValue,
            },
          },
        },
      };
      try {
        const prokDowngrade = await this.updateInvestmentSurvey({ input });
        this.data = clone(this.$store.state.user.data);
        this.loading = false;
        this.closeProkWarningModal();
        if (prokDowngrade) {
          this.downgrade = true;
          this.changeProkModal = true;
        }
        this.$notify.success(this.$t('settings.knowledgeAndExperience.success'));
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
      }
    },
    back() {
      this.$router.push({ name: this.nextRoute });
    },
    reset() {
      this.updateUser(this.data);
      this.resetData = clone(this.data);
    },
    clear() {
      this.resetData = null;
    },
    closeWarningModal() {
      this.warningModal = false;
    },
    closeProkWarningModal() {
      this.prokWarningModal = false;
    },
    closeChangeProkModal() {
      this.changeProkModal = false;
    },
    closeDowngradeProkModal() {
      this.downgradeProkModal = false;
    },
  },
};
</script>
